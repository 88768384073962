var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "iconfont icon-fanhui" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c(
            "el-form",
            {
              ref: "recordDetail",
              attrs: {
                rules: _vm.rules,
                model: _vm.recordDetail,
                "label-width": "170px"
              }
            },
            [
              _c("div", { staticClass: "detail-area" }, [
                _c("div", { staticClass: "area-title" }, [
                  _c("p", { staticClass: "title" }, [_vm._v(" 服务费详情 ")])
                ]),
                _c(
                  "div",
                  { staticClass: "detail-inner" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "用信编号：", prop: "useCode" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.recordDetail.useCode) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费编号：",
                                  prop: "serviceChargeCode"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.serviceChargeCode) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "用信金额：",
                                  prop: "useAmount"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.useAmount) +
                                    " 元 "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费总额：",
                                  prop: "serviceChargeAmount"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.recordDetail.serviceChargeAmount
                                    ) +
                                    " 元 "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "area-title" }, [
                  _c("p", { staticClass: "title" }, [_vm._v(" 核心企业分成 ")])
                ]),
                _c(
                  "div",
                  { staticClass: "detail-inner" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "核心企业：", prop: "coreName" }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.recordDetail.coreName) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费核心企业占比：",
                                  prop: "coreProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.coreProportion) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费核心企业所分金额：",
                                  prop: "coreAmount"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.coreAmount) +
                                    " 元 "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "area-title" }, [
                  _c("p", { staticClass: "title" }, [_vm._v(" 资金方分成 ")])
                ]),
                _c(
                  "div",
                  { staticClass: "detail-inner" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "资金方企业：",
                                  prop: "capName"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.recordDetail.capName) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费资金方占比：",
                                  prop: "capProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.capProportion) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费资金方所分金额：",
                                  prop: "capAmount"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.capAmount) +
                                    " 元 "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "area-title" }, [
                  _c("p", { staticClass: "title" }, [_vm._v(" 运营方分成 ")])
                ]),
                _c(
                  "div",
                  { staticClass: "detail-inner" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "运营方：",
                                  prop: "channelName"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.channelName) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费运营方占比：",
                                  prop: "channelProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.channelProportion) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费运营方所分金额：",
                                  prop: "channelAmount"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.channelAmount) +
                                    " 元 "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "area-title" }, [
                  _c("p", { staticClass: "title" }, [_vm._v(" 平台技术分成 ")])
                ]),
                _c(
                  "div",
                  { staticClass: "detail-inner" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费平台技术占比：",
                                  prop: "tenantTechProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.recordDetail.tenantTechProportion
                                    ) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费平台运营占比：",
                                  prop: "tenantSystemProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.recordDetail.tenantSystemProportion
                                    ) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费平台技术所分金额：",
                                  prop: "tenantTechAmount"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.recordDetail.tenantTechAmount) +
                                    " 元 "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "服务费平台运营所分金额：",
                                  prop: "tenantSystemAmount"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.recordDetail.tenantSystemAmount
                                    ) +
                                    " 元 "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }