<!-- 服务费模板详情 -->
<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button icon="iconfont icon-fanhui" @click="$router.back()">
          返回
        </el-button>
      </template>
    </page-title>
    <div class="partition-area">
      <el-form ref="recordDetail" :rules="rules" :model="recordDetail" label-width="170px">
        <div class="detail-area">
          <div class="area-title">
            <p class="title">
              服务费详情
            </p>
          </div>
          <div class="detail-inner">
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="用信编号：" prop="useCode">
                  {{ recordDetail.useCode }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费编号：" prop="serviceChargeCode">
                  {{ recordDetail.serviceChargeCode }}
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="用信金额：" prop="useAmount">
                  {{ recordDetail.useAmount }} 元
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费总额：" prop="serviceChargeAmount">
                  {{ recordDetail.serviceChargeAmount }} 元
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="area-title">
            <p class="title">
              核心企业分成
            </p>
          </div>
          <div class="detail-inner">
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="核心企业：" prop="coreName">
                  {{ recordDetail.coreName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费核心企业占比：" prop="coreProportion">
                  {{ recordDetail.coreProportion }}%
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="服务费核心企业所分金额：" prop="coreAmount">
                  {{ recordDetail.coreAmount }} 元
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="area-title">
            <p class="title">
              资金方分成
            </p>
          </div>
          <div class="detail-inner">
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="资金方企业：" prop="capName">
                  {{ recordDetail.capName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费资金方占比：" prop="capProportion">
                  {{ recordDetail.capProportion }}%
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="服务费资金方所分金额：" prop="capAmount">
                  {{ recordDetail.capAmount }} 元
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="area-title">
            <p class="title">
              运营方分成
            </p>
          </div>
          <div class="detail-inner">
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="运营方：" prop="channelName">
                  {{ recordDetail.channelName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费运营方占比：" prop="channelProportion">
                  {{ recordDetail.channelProportion }}%
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="服务费运营方所分金额：" prop="channelAmount">
                  {{ recordDetail.channelAmount }} 元
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div class="area-title">
            <p class="title">
              平台技术分成
            </p>
          </div>
          <div class="detail-inner">
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="服务费平台技术占比：" prop="tenantTechProportion">
                  {{ recordDetail.tenantTechProportion }}%
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费平台运营占比：" prop="tenantSystemProportion">
                  {{ recordDetail.tenantSystemProportion }}%
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费平台技术所分金额：" prop="tenantTechAmount">
                  {{ recordDetail.tenantTechAmount }} 元
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="服务费平台运营所分金额：" prop="tenantSystemAmount">
                  {{ recordDetail.tenantSystemAmount }} 元
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
  </el-main>
</template>
<script>
import axios from 'axios'
export default {
  components: {
  },
  data () {
   return {
      isShow: false,
      recordDetail: {
	    capAmount: '',
	    capName: '',
	    capProportion: '',
	    channelAmount: '',
	    channelName: '',
	    channelProportion: '',
	    coreAmount: '',
	    coreName: '',
	    coreProportion: '',
	    repaymentType: '',
	    serviceChargeAmount: '',
	    serviceChargeProportion: '',
	    serviceChargeCode: '',
	    tenantSystemAmount: '',
	    tenantSystemProportion: '',
	    tenantTechAmount: '',
	    tenantTechProportion: '',
	    useAmount: '',
	    useCode: ''
      },
      loading: false ,
      rules: {},
      capList: [],
      coreList: [],
      create_op: false,
      edit_op: false,
      detail_op: false,
  }
  },
  watch: {},
  created () {
    this.id = this.$route.query.id
    this.getDataById()

  },
  methods: {
    getDataById(){
     this.api.serviceCharge.getDetailInfo({id:this.id}).then(res => {
        this.recordDetail = res.data.data
        console.log(recordDetail)
      })
    },
    getWidth () {
      this.conWidth.width = window.innerHeight - 120 + 'px'
    },
    created () {
      window.addEventListener('resize', this.getWidth)
      this.getWidth()
    }
  }
}
</script>
<style scoped>
  .input_w80{
    width:80%;
  }
</style>
